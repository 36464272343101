<style lang="scss" scoped>
.header {
	margin-top: 10vh;
	font-size: 0.4rem;
	text-align: center;
}
#code_img {
	margin-top: 0.6rem;
	display: flex;
	justify-content: center;
}
.last_pay_time {
	font-size: 0.24rem;
	text-align: center;
	margin-top: 0.2rem;
}
.sign {
	font-size: 0.28rem;
	text-align: center;
	margin-top: 0.6rem;
}
.count {
	margin: 1rem 0.3rem 0.31rem;
	height: 0.8rem;
	border-radius: 8px;
	font-size: 0.3rem;
	text-align: center;
	color: #fff;
	background-color: $color_main;
	line-height: 0.8rem;
}
</style>

<template>
	<div class="pay_code">
		<div class="header">微信支付</div>
		<div id="code_img"></div>
		<p class="last_pay_time" v-text="`支付码有效时间：${paymentInfo.timeExpire}`"></p>
		<p class="sign">长按二维码保存至手机，微信识别进行支付</p>
		<div class="count" @click="getPayment">查询支付结果</div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { Toast } from 'vant';
import { getBillPaymentInfo, getBillInfo } from '@/request/api';
import { billState } from '@/config/fixedParams';

export default {
	name: 'payCode',
	components: {
		[Toast.name]: Toast,
	},
	props: {
		paymentInfo: {
			type: Object,
			require: true,
		},
	},
	data() {
		return {};
	},
	created() {
		this.qrcode(this.paymentInfo.codeurl);
	},
	methods: {
		qrcode(payUrl) {
			this.$nextTick(() => {
				new QRCode('code_img', {
					text: payUrl,
					width: 235,
					height: 235,
				});
			});
		},

		getPayment() {
			getBillInfo(this.paymentInfo.billNo).then(res => {
				if (res.billInfo.billState == 3 || res.billInfo.billState == 4) {
					this.$router.push({
						name: 'insurePaySuccess',
						params: {
							// protectBillUrl: res.insBillPFile.urlPath
							protectBillUrl: res.insBillPFile ? (res.insBillPFile.urlPath ? res.insBillPFile.urlPath : '') : '',
						},
					});
				} else {
					Toast(billState[String(res.billInfo.billState)]);
				}
			});
		},
	},
};
</script>
